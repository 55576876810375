import { atom } from 'jotai';
import { DisplayMode } from '@rocco/types/enum/display-mode';

/**
 * Atom to store and manage the current display mode state
 * Defaults to DisplayMode.Desktop as the initial value
 * Should be used only in the root component, not in other components, use uiContextAtomDisplayMode instead
 */
export const _displayModeAtom = atom<DisplayMode>(DisplayMode.Desktop);

/**
 * Derived atom that provides a boolean record of display modes
 * Useful for conditional rendering based on display mode
 */
export const uiContextAtomDisplayMode = atom(get => {
    const displayMode = get(_displayModeAtom);

    return {
        isMobile: displayMode === DisplayMode.Mobile,
        isDesktop: displayMode === DisplayMode.Desktop,
    };
});
